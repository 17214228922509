const getEventFor = type => {
  if (type?.subCategory1.includes("support:contact")) {
    return "support:contact:form"
  }
  if (type?.subCategory1.includes("support:find a service part")) {
    return "support:find-a-service-part"
  }
  if (type?.subCategory1.includes("my account:product registration")) {
    return "my account:register offline kohler products:product information"
  }
  return type?.subCategory1 ?? ""
}

export { getEventFor }
